<template>
    <div style="overflow:hidden;height:100%">
        <Head ref="head"></Head>
        <!-- 轮播图 -->
        <Banner></Banner>
        <section>
            <div
                    class="mark wrap-block"
                    id="news"
                    style="background: #F4F4F4;border-radius: 0px 0px 0px 0px;opacity: 1;margin-top:23px"
            >
                <div class="content-block service-idea">
                    <div class="service-idea">
                        <div class="idea-left">
                            <div class="block-left"></div>
                            <div class="news-title">
                                {{ newsItem.news }}
                            </div>
                            <img class="" :src="newsItem.cover" alt="暂无新闻图片"/>
                            <div class="news-content" v-html='newsItem.content'>
                            </div>
                            <div class="more-button mb20" @click="skipto1news">
                                了解详情<i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <div class="idea-right">
                            <div class="newsList-title">
                                新闻资讯
                                <span class="service-info-title-more" @click="jumpList(3)">更多</span>
                            </div>
                            <dv-scroll-board
                                    @click="skipto"
                                    :config="config"
                                    class="datav-table"
                                    style="height: 100%"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                    class="mark content-block service-block"
                    style="margin-top:60px"
                    id="standard"
            >
                <div class="service-block">
                    <p class="service-info-title">行业案例</p>
                    <div class="standard_1 standard">
                        <img :src="chenicalStandardList[0].image" alt=""/>
                        <div class="standard_box">
                            <div class="standard_title">
                                {{ chenicalStandardList[0].title }}
                            </div>
                            <div class="standard_content">
                                {{ chenicalStandardList[0].briefIntroduction }}
                            </div>
                            <div class="more-button" @click="clickStand(0)">
                                查看更多<i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="standard_2 standard">
                        <div class="standard_box">
                            <div class="standard_title">
                                {{ chenicalStandardList[1].title }}
                            </div>
                            <div class="standard_content">
                                {{ chenicalStandardList[1].briefIntroduction }}
                            </div>
                            <div class="more-button" @click="clickStand(1)">
                                查看更多<i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                        <img :src="chenicalStandardList[1].image" alt=""/>
                    </div>
                    <div></div>
                    <!-- <div class="news-card">
                      <el-card :body-style="{ padding: '0px' }" class="news-card-item">
                        <div
                          v-html="chenicalStandardList[0].briefIntroduction"
                          class="rich"
                        />
                        <div style="padding: 14px;">
                          <span>{{ chenicalStandardList[0].title }}</span>
                          <div class="bottom clearfix">
                            <el-button type="text" class="button" @click="clickStand(0)"
                              >查看更多</el-button
                            >
                          </div>
                        </div>
                      </el-card>
                      <el-card :body-style="{ padding: '0px' }" class="news-card-item">
                        <div
                          v-html="chenicalStandardList[1].briefIntroduction"
                          class="rich"
                        />
                        <div style="padding: 14px;">
                          <span>{{ chenicalStandardList[1].title }}</span>
                          <div class="bottom clearfix">
                            <el-button type="text" class="button" @click="clickStand(1)"
                              >查看更多</el-button
                            >
                          </div>
                        </div>
                      </el-card>
                    </div> -->
                </div>
            </div>
            <div class="mark content-block five-block" id="module">
                <p class="service-info-title">平台优势</p>
                <p class="service-info-subtitle">
                    <!-- 江苏省化工园区信息化发展联盟 -->
                </p>
                <div class="service-list" >
                    <div class="service-title" @click="employClick(1)">
                    <!--    <el-popover placement="right" width="220" > -->
                            <div
                                    class="service-title-shadow bgWisePark"
                                    slot="reference"
                            ></div>
                           <!-- <div class="detail-box">
                                <div
                                        class="single-detail w136"
                                        v-for="(item, index) in wiseParkList"
                                        @click="goQuestion(item.questionType,item.url)"
                                >
                                    {{ item.title }}
                                </div>
                            </div> -->
                       <!-- </el-popover> -->
					 
                        <p class="service-name" >{{ "简单易学" }}</p>
                    </div>
                    <div class="service-title" @click="employClick(2)">
                      <!-- <el-popover placement="right" width="220" > -->
                            <div class="service-title-shadow bgFusion" slot="reference"></div>
                           <!-- <div class="detail-box">
                                <div
                                        class="single-detail"
                                        v-for="(item, index) in fusionList"
                                        @click="goHref(item.url)"
                                >
                                    {{ item.title }}
                                </div>
                            </div> -->
                      <!--  </el-popover> -->
                        <p class="service-name">{{ "功能强大" }}</p>
                    </div>
                    <div class="service-title" @click="employClick(3)">
                      <!--  <el-popover placement="right" width="220" trigger="hover"> -->
                            <div
                                    class="service-title-shadow bgChemicalEnterprise"
                                    slot="reference"
                            ></div>
                           <!-- <div class="detail-box">
                                <div
                                        class="single-detail w136"
                                        v-for="(item, index) in chemicalEnterpriseList"
                                        @click="goQuestion(item.questionType,item.url)"
                                >
                                    {{ item.title }}
                                </div>
                            </div> -->
                      <!--  </el-popover> -->
                        <p class="service-name">{{ "轻松开发复杂系统" }}</p>
                    </div>
                </div>
            </div>
            <div class="mark content-block service-block" id="experts" style="min-width: 1200px;">
                <div class="service-block expert-box">
                    <p class="service-info-title" style="padding: 0px 100px;">
                       客户池
                        <span class="service-info-title-more" @click="jumpList(1)">更多</span>
                    </p>
                    <swiper
                            ref="mySwiper"
                            :options="swiperOption"
                            class="swiperBox"
                            @mouseenter="onMouseEnter"
                            @mouseleave="onMouseLeave"
                    >
                        <swiper-slide
                                v-for="(item, index) in chemicalPeopleList"
                                :key="index"
                                style="display:flex;justify-content:center;"
                        >
                           <!-- <div class="single-swiper" @click="peopleClick(item.id)"> -->
								 <div class="single-swiper" >
								<div style="width: 208px; height: 264px;text-align:center;line-height:264px"> <img :src="item.phoneUrl" alt=""  style="display:inline-block;vertical-align:middle; width:208px;height:80px;"/></div>                              
                                <div class="swiper-name">{{ item.name }}</div>
                                <div class="synopsis">
                                    {{ item.synopsis }}
                                </div>
                            </div>
                        </swiper-slide>
                        <div
                                slot="button-prev"
                                class="swiper-button-prev"
                                @click="prevBtn"
                        ></div>
                        <div
                                slot="button-next"
                                class="swiper-button-next"
                                @click="nextBtn"
                        ></div>
                    </swiper>
                </div>
            </div>
            <div class="mark content-block five-block" id="service">
                <p class="service-info-title" style="max-width: 1440px;margin: 0px auto">
                    <span style="display: inline-block;margin-left: 100px">平台功能</span>
                    <span class="service-info-title-more" style="margin-right: 100px" @click="jumpList(2)">更多</span>
                </p>
                <div class="service-box">
                    <div class="service-inner">
                        <el-carousel
                                indicator-position="none"
                                arrow="never"
                                :interval="5000"
                                style="width: 1180px;height:500px"
                        >
                            <el-carousel-item
                                    v-for="(list, index) in catholicismImgList"
                                    :key="index"
                                    class="el-car-item"
                            >
                                <!-- <div
                                        v-for="item in list"
                                        class="service-item"
                                        @click="serviceClick(item.id)"
                                > -->
								<div
								        v-for="item in list"
								        class="service-item"
								       
								>
                                    <div style="display:flex;">
                                        <span class="gray-dot"></span>
                                        <span class="svc-title" :title="item.enterpriseName">{{
                      item.enterpriseName
                    }}</span>
                                    </div>
                                    <div class="svc-content">
                                        {{ item.enterprisSynopsis }}
                                    </div>
                                    <div class="tag-list">
                                        <div
                                                v-for="(el, index) in item.enterprisType"
                                                class="single-tag"
                                                :class="sstt[index]"
                                                :title="el"
                                        >
                                            {{ el }}
                                        </div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </section>
        <Foot></Foot>
    </div>
</template>

<script>
    // import '@/assets/js/index.js'

    import {get} from "../utils/request";
    import Banner from "./components/Banner.vue";
    import {swiper, swiperSlide} from "vue-awesome-swiper";
    import "swiper/dist/css/swiper.css";
    import Head from "../views/components/Head.vue";
    import Foot from "../views/components/Foot.vue";

    export default {
        components: {
            Banner,
            swiper,
            swiperSlide,
            Head,
            Foot,
        },
        data() {
            return {
                sstt: ["high", "Medium", "low"],
                config: {
                    oddRowBGC: "#F4F4F4",
                    evenRowBGC: "#F4F4F4",
                    waitTime: 10 * 1000,
                    align: "left",
                    rowNum: 8,
                    data: [],
                    columnWidth: [700, 120],
                    carousel: "single",
                },
                coverUrl: "",
                chemicalPeopleList: [],
                chemicalServiceList: [],
                catholicismImgList: [],
                chenicalStandardList: [
                    {briefIntroduction: "", title: ""},
                    {briefIntroduction: "", title: ""},
                ],
                newsItem: {
                    id: null,
                    news: "",
                    cover: "",
                    content: "",
                },
                newsList: [],
                swiperOption: {
                    slidesPerView: 5, // 个数
                    spaceBetween: 10, // 距离
                    navigation: {
                        prevEl: "swiper-button-prev", // 后退按钮的css选择器或HTML元素。
                        nextEl: "swiper-button-next", // 前进按钮的css选择器或HTML元素。
                    },
                    loop: true, // 开启循环模式
                    autoplay: {
                        delay: 30000,
                        disableOnInteraction: false, // 当用户滑动图片后继续自动轮播
                    },
                },
                top5: [],
                fusionList: [],
                wiseParkList: [],
                chemicalEnterpriseList: [],
            };
        },
        created() {
        },
        mounted() {
            this.getNewsList();
            this.getChemicalPeople();
            this.getChemicalService();
            this.getChenicalStandard();
            this.getFusion();
            this.getWisePark();
            this.getChemicalEnterprise();
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper;
            },
            swiperService() {
                return this.$refs.swiperService.swiper;
            },
        },
        methods: {
            jumpList(type) {
                localStorage.type = type;
                const routeData = this.$router.resolve({
                    name: "HomeChemicalPeopleOrService",
                });
                window["open"](routeData.href, "_blank");
            },
            serviceClick(id) {
                localStorage.serviceId = id;
                const routeData = this.$router.resolve({
                    name: "detailService",
                });
                window["open"](routeData.href, "_blank");
            },
			employClick(id) {
			    localStorage.employId = id;
			    const routeData = this.$router.resolve({
			        name: "detailEmploy",
			    });
			    window["open"](routeData.href, "_blank");
			},
            peopleClick(id) {
                localStorage.peopleId = id;
                const routeData = this.$router.resolve({
                    name: "detailPeople",
                });
                window["open"](routeData.href, "_blank");
            },
            top5Click(item) {
                if (item.type == 1) {
                    if (!localStorage.user) {
                        this.$refs.head.dialogVisible = true;
                        setTimeout(() => {
                            this.$message({
                                message: "请先登录",
                                type: "warning",
                            });
                        }, 100);
                        return;
                    }

                    get("/api/checkToken").then((res) => {
                        if (res.data.code != 200) {
                            localStorage.removeItem("token");
                            localStorage.removeItem("user");
                            this.user = null;
                            this.$refs.head.dialogVisible = true;
                            setTimeout(() => {
                                this.$message({
                                    message: "登录过期，请重新登录",
                                    type: "warning",
                                });
                            }, 100);
                            return;
                        }else {
                            localStorage.questionnaireType = item.questionType;
                            const newHref = this.$router.resolve({name: "questionnaire"});
                            window.open(newHref.href, "_blank");
                            return;
                        }
                    });
                } else {
                    if (item.url) window.open(item.url);
                }
            },
            goHref(url) {
                window.open(url, "_blank");
            },
            goQuestion(questionType,url) {
                if (!localStorage.user) {
                    this.$refs.head.dialogVisible = true;
                    setTimeout(() => {
                        this.$message({
                            message: "请先登录",
                            type: "warning",
                        });
                    }, 100);
                    return;
                }

                get("/api/checkToken").then((res) => {
                    if (res.data.code != 200) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        this.user = null;
                        this.$refs.head.dialogVisible = true;
                        setTimeout(() => {
                            this.$message({
                                message: "登录过期，请重新登录",
                                type: "warning",
                            });
                        }, 100);
                        return;
                    } else {
                        /*var jumpUrl = '';
                        if (questionType == 1 && url != null && url != ''){
                            // 智慧园区-自评价
                            jumpUrl = url;
                        }
                        if (questionType == 3 && url != null && url != ''){
                            // 化工企业-自评价
                            jumpUrl = url;
                        }
                        if (jumpUrl != ''){
                            window.open(jumpUrl, "_blank");
                            return;
                        }*/
                        localStorage.questionnaireType = questionType;
                        const newHref = this.$router.resolve({name: "questionnaire"});
                        window.open(newHref.href, "_blank");
                    }
                });
            },
            clickStand(index) {
                localStorage.standId = this.chenicalStandardList[index].id;
                const routeData = this.$router.resolve({
                    name: "detailStand",
                });
                window["open"](routeData.href, "_blank");
            },
            skipto(config) {
                console.log(this.newsList[config.rowIndex].id, "config");
                localStorage.newsId = this.newsList[config.rowIndex].id;
                const routeData = this.$router.resolve({
                    name: "detail",
                });
                window["open"](routeData.href, "_blank");
            },
            skipto1news() {
                if (this.newsItem.id) {
                    localStorage.newsId = this.newsItem.id;
                    const routeData = this.$router.resolve({
                        name: "detail",
                    });
                    window["open"](routeData.href, "_blank");
                } else {
                    this.$message({
                        message: "暂无详情",
                        type: "warning",
                    });
                }
            },
            // getChemicalEnterprise() {
            //   get("/api/getChemicalEnterprise").then((res) => {
            //     if (res.status == 200) {
            //       this.top5 = res.data.data;
            //     }
            //   });
            // },
            // 两化融合
            getFusion() {
                get("/api/getChemicalEnterprise?groupId=" + 1).then((res) => {
                    if (res.status == 200) {
                        this.fusionList = res.data.data;
                    }
                });
            },
            // 智慧园区
            getWisePark() {
                get("/api/getChemicalEnterprise?groupId=" + 2).then((res) => {
                    if (res.status == 200) {
                        this.wiseParkList = res.data.data;
                    }
                });
            },
            // 化工企业
            getChemicalEnterprise() {
                get("/api/getChemicalEnterprise?groupId=" + 3).then((res) => {
                    if (res.status == 200) {
                        this.chemicalEnterpriseList = res.data.data;
                    }
                });
            },
            getBannerOurText() {
                get("/api/getBannerOurText").then((res) => {
                    if (res.status == 200) {
                        this.ourText = res.data.data;
                    }
                });
            },
            // 新闻
            getNewsList() {
                get("/api/getNewsList", {isMore: 0}).then((res) => {
                    if (res.status == 200) {
                        this.newsList = res.data.data;
                        if (this.newsList.length > 0) {
                            // 1、定义正则规则
                            let re1 = new RegExp("<.+?>", "g");
                            this.newsList.map((el) => {
                                // 2、将富文本编辑器生成的文本进行替换
                                this.$set(el, "content", el.newsContent.replace(re1, ""));
                            });
                            this.newsItem = this.newsList.shift();
                            console.log(this.newsItem, "newsItem");
                        } else {
                            return;
                        }
                        let newsData = [];
                        this.newsList.map((el) => {
                            newsData.push([
                                this.getNewsTitle(el.news),
                                '<span style="color:#999999;">' +
                                this.getNewsTime(el.createTime.split("T")[0]) +
                                "</span>",
                            ]);
                        });
                        let nowData = this.config;
                        nowData.data = newsData;
                        this.config = {...nowData};
                        this.coverUrl = res.data.data?.[0].cover;
                    }
                });
            },
            // 化工专家
            getChemicalPeople() {
                get("/api/getChemicalPeople", {isMore: 0}).then((res) => {
                    if (res.status == 200) {
                        this.chemicalPeopleList = res.data.data;
                        console.log("chemicalPeopleList");
                        console.log(this.chemicalPeopleList);
                    }
                });
            },
            // 化工服务商
            getChemicalService() {
                get("/api/getChemicalService", {isMore: 0}).then((res) => {
                    if (res.status == 200) {
                        let list = res.data.data;
                        if (list.length > 0) {
                            list.map((el) => {
                                el.enterprisType = el.enterprisType.split(",");
                                el.enterprisType = el.enterprisType.splice(0, 3);
                            });
                            this.chemicalServiceList = list;
                            this.catholicismImgList = this.setImgData(list, 8);
                            console.log(this.catholicismImgList);
                        }
                    }
                });
            },
            // 处理图片数据通用
            // @param oriList原数组 groupNum每组数量
            setImgData(oriList, groupNum) {
                let newDataList = [];
                let current = 0;
                if (oriList && oriList.length > 0) {
                    for (let i = 0; i <= oriList.length - 1; i++) {
                        if (i % groupNum !== 0 || i === 0) {
                            if (!newDataList[current]) {
                                newDataList.push([oriList[i]]);
                            } else {
                                newDataList[current].push(oriList[i]);
                            }
                        } else {
                            current++;
                            newDataList.push([oriList[i]]);
                        }
                    }
                }
                return [...newDataList];
            },
            // 化工行业标准
            getChenicalStandard() {
                get("/api/getChenicalStandard").then((res) => {
                    if (res.status == 200) {
                        this.chenicalStandardList = res.data.data;
                    }
                });
            },
            prevBtn() {
                this.swiper.slidePrev();
            },
            nextBtn() {
                this.swiper.slideNext();
            },
            prevBtnService() {
                this.swiperService.slidePrev();
            },
            nextBtnService() {
                this.swiperService.slideNext();
            },
            /** 鼠标移入 */
            onMouseEnter() {
                this.swiper.autoplay.stop();
            },
            /** 鼠标移出 */
            onMouseLeave() {
                this.swiper.autoplay.start();
            },
            /** 鼠标移入 */
            onMouseEnterService() {
                this.swiperService.autoplay.stop();
            },
            /** 鼠标移出 */
            onMouseLeaveService() {
                this.swiperService.autoplay.start();
            },
            getNewsTitle(title) {
                return (
                    '<i class="el-icon-right" style="color:#3370ff;margin-right:6px;font-size:16px"></i>' +
                    '<span class="pointer14" title="' +
                    title +
                    '">' +
                    title +
                    "</span>"
                );
            },
            getNewsTime(time) {
                return '<span class="pointer14">' + time + "</span>";
            },

        },
    };
</script>
<style lang="less" scoped>
    // 主体一
    .slideBox {
        width: 100%;
        overflow: hidden;
        position: relative;
        margin-bottom: 56px;
    }

    .content-block.service-idea {
        // background-color: #f5f5f5;
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
        text-align: center;
    }

    .content-block.service-idea .service-idea {
        max-width: 1500px;
        overflow: hidden;
        display: flex;
    }

    .content-block.service-idea .idea-left {
        img {
            width: 580px;
            height: 177px;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
        }
    }

    .content-block.service-idea .idea-right {
        height: 300px;
        width: calc(100% - 600px);
        margin-left: 100px;
    }

    .content-block.service-idea .idea-right .block-line {
        margin: 0 0 12px 0;
        width: 24px;
        height: 4px;
        background-color: #3370ff;
        border-radius: 100px;
    }

    .content-block.service-idea .idea-right .idea-title {
        font-weight: 700;
        font-size: 28px;
        position: relative;
        bottom: 2px;
        color: #000;
    }

    .content-block.service-idea .idea-right .idea-subtitle {
        font-size: 16px;
        line-height: 1;
        color: #646a73;
        margin-top: 10px;
    }

    .content-block.service-idea .idea-right img {
        width: 500px;
        height: 200px;
        -o-object-fit: cover;
        object-fit: cover;
        padding: 10px;
        border: 2px dashed #d3dce6;
    }

    .content-block.service-idea .idea-right .idea-desc {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        max-height: 118px;
        max-width: 850px;
        font-size: 14px;
        line-height: 30px;
        color: #646a73;
    }

    .pointer14 {
        cursor: pointer;
        font-size: 14px;
    }

    // 化工企业
    .wrap-block .content-block {
        position: relative;
        overflow: hidden;
    }

    .service-block {
        max-width: 1440px;
        margin: 0 auto;
        text-align: center;
    }

    .five-block {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .block-left {
        width: 36px;
        height: 6px;
        background-color: #3370ff;
        // border-radius: 100px;
    }

    .news-title {
        margin: 36px 0;
        height: 30px;
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        text-align: left;
    }

    .news-content {
        width: 580px;
        height: 74px;
        font-size: 14px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #545454;
        line-height: 18px;
        // 溢出隐藏
        overflow: hidden;
        // 显示省略号
        text-overflow: ellipsis;
        // 和-webkit-line-clamp结合使用
        // 将对象作为弹性伸缩盒子模型显示
        display: -webkit-box;
        // 用来限制在一个块元素显示的文本的行数，
        // 它需要组合其他的 WebKit 属性
        // 写什么数字表示最多显示几行。
        -webkit-line-clamp: 4;
        // 和-webkit-line-clamp结合使用
        // 设置伸缩盒对象的子元素的排列方式
        -webkit-box-orient: vertical;
        text-align: left;
        margin: 19px 0 34px;
    }

    .more-button {
        width: 106px;
        height: 37px;
        line-height: 37px;
        background: #235ae8;
        border-radius: 55px 55px 55px 55px;
        opacity: 1;
        font-size: 14px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        // font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        text-align: center;
    }

    .mb20 {
        margin-bottom: 20px;
    }

    .newsList-title {
        text-align: left;
        height: 30px;
        font-size: 20px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        font-weight: bold;
        line-height: 23px;
        margin: 40px 0 35px 14px;
    }

    .block-line {
        margin: 0 auto 12px;
        width: 24px;
        height: 4px;
        background-color: #3370ff;
        border-radius: 100px;
    }

    .service-info-title {
        font-weight: 700;
        font-size: 28px;
        position: relative;
        bottom: 2px;
        color: #000;
    }

    .service-info-subtitle {
        font-size: 16px;
        line-height: 1.5;
        color: #646a73;
        margin-top: 30px;
    }

    .service-list {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        margin: 70px auto 50px auto;
        display: flex;
        background: url("../assets/img/five_bg.png") no-repeat;
        height: 398px;
        background-size: cover;
    }

    .service-title-shadow {
        width: 124px;
        height: 124px;
        cursor: pointer;
        transition: all 0.5s; /* 所有的属性变化在0.5s的时间段内完成 */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bgFusion {
        background: url(../assets/img/Fusion.png) no-repeat;
    }

    .bgWisePark {
        background: url(../assets/img/WisePark.png) no-repeat;
    }

    .bgChemicalEnterprise {
        background: url(../assets/img/ChemicalEnterprise.png) no-repeat;
    }

    .bgFusion:hover {
        background: url(../assets/img/Fusion_a.png) no-repeat !important;
    }

    .bgWisePark:hover {
        background: url(../assets/img/WisePark_a.png) no-repeat !important;
    }

    .bgChemicalEnterprise:hover {
        background: url(../assets/img/ChemicalEnterprise_a.png) no-repeat !important;
    }

    // .service-title-shadow :hover {
    //   background: #235ae8 !important;
    // }
    .service-title-shadow img:hover {
        transform: scale(1.05); /* 鼠标放到图片上的时候图片按比例放大1.5倍   */
    }

    .service-block .news-card {
        padding-top: 40px;
        margin: 0 auto 50px auto;
        display: flex;
        justify-content: space-evenly;
    }

    .service-block .news-card .news-card-item {
        width: 590px;
        // height: 255px;
        position: relative;
        cursor: pointer;

        .rich {
            height: 300px;
            overflow: hidden;
        }
    }

    .news-card-item img:hover {
        transform: scale(1.05); /* 鼠标放到图片上的时候图片按比例放大1.5倍   */
    }

    .news-card-item img {
        cursor: pointer;
        transition: all 0.5s; /* 所有的属性变化在0.5s的时间段内完成 */
    }

    .swiperBox {
        margin: 10px 50px;
    }

    .service-name {
        margin-top: 30px;
        font-weight: 600;
        height: 24px;
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
    }

    .swiper-button-prev {
        width: 25px;
        height: 18px;
    }

    .swiper-button-next {
        width: 25px;
        height: 18px;
    }

    .single-swiper {
        cursor: pointer;
        width: 208px;
        height: 376px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        color: #2c2828;

        img {
            width: 208px;
            height: 264px;
        }

        .swiper-name {
            padding: 23px 0 10px;
            height: 24px;
            font-size: 16px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 600;
            color: #000000;
            line-height: 24px;
            border-bottom: 1px solid #ffc52f;
        }

        .synopsis {
            padding-top: 4px;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
            // 控制行数
            -webkit-line-clamp: 2; //超出两行隐藏
            -webkit-box-orient: vertical; // 从上到下垂直排列子元素
            font-size: 16px;
            font-weight: normal;
        }
    }

    .datav-table {
        ::v-deep .ceil {
            text-align: left;
            color: #000;
            cursor: pointer;
        }
    }

    /* @media screen and (min-width: 960px) and (max-width: 1200px) {
      .el-carousel__item {
        width: 1200px;
        background-color: #ccc;
      }
    } */
    .standard {
        width: 1200px;
        height: 266px;
        background: #f2f6ff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        display: flex;
        text-align: left;

        img {
            width: 428px;
            height: 266px;
        }

        .standard_box {
            width: calc(100% - 428px);
            height: 100%;
            padding: 35px 65px 0 65px;
        }

        .standard_title {
            height: 27px;
            font-size: 18px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #000000;
            line-height: 27px;
            margin-bottom: 22px;
        }

        .standard_content {
            width: 642px;
            height: 124px;
            font-size: 14px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #545454;
            line-height: 18px;
            margin-bottom: 10px;
            // 溢出隐藏
            overflow: hidden;
            // 显示省略号
            text-overflow: ellipsis;
            // 和-webkit-line-clamp结合使用
            // 将对象作为弹性伸缩盒子模型显示
            display: -webkit-box;
            // 用来限制在一个块元素显示的文本的行数，
            // 它需要组合其他的 WebKit 属性
            // 写什么数字表示最多显示几行。
            -webkit-line-clamp: 7;
            // 和-webkit-line-clamp结合使用
            // 设置伸缩盒对象的子元素的排列方式
            -webkit-box-orient: vertical;
        }
    }

    .standard_1 {
        margin: 45px auto 0;
    }

    .standard_2 {
        margin: 20px auto 60px;
    }

    .expert-box {
        height: 400px;
        padding: 50px 0;
    }

    .service-inner {
        width: 1180px;
        height: 500px;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
    }

    .service-box {
        margin: 50px 0;
        height: 525px;
        background: url("../assets/img/svc_bg.png") no-repeat;
        background-size: cover;

        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .service-item {
            cursor: pointer;
            width: calc((100% - 210px) / 4);
            height: 180px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            margin-right: 26px;
            margin-top: 46px;
            padding: 12px;

            .gray-dot {
                width: 18px;
                height: 18px;
                background: #d9d9d9;
                opacity: 1;
                display: inline-block;
                border-radius: 100%;
                margin-top: 4px;
            }

            .svc-title {
                padding-left: 5px;
                height: 24px;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
                width: calc(100% - 10px);
                // 溢出隐藏
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .svc-content {
                padding: 13px 0 13px 0;
                width: 236px;
                height: 75px;
                font-size: 14px;
                font-family: Source Han Sans CN-Normal, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                // 溢出隐藏
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                // 用来限制在一个块元素显示的文本的行数，
                // 它需要组合其他的 WebKit 属性
                // 写什么数字表示最多显示几行。
                -webkit-line-clamp: 5;
                // 和-webkit-line-clamp结合使用
                // 设置伸缩盒对象的子元素的排列方式
                -webkit-box-orient: vertical;
            }
        }

        .tag-list {
            margin-top: 12px;
            display: flex;
        }

        .single-tag {
            height: 22px;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            text-align: center;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 0 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .high {
            border: 1px solid #ffc52f;
            color: #ffc52f;
        }

        .Medium {
            border: 1px solid #235ae8;
            color: #235ae8;
        }

        .low {
            border: 1px solid #ff932f;
            color: #ff932f;
        }
    }

    // 走马灯
    .el-car-item {
        height: 500px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .detail-box {
        height: 194px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        padding: 50px 0;

        .single-detail {
            width: 100px;
            height: 38px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 20px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #235ae8;
            line-height: 38px;
            cursor: pointer;
        }

        .w136 {
            width: 136px !important;
        }
    }

    /*“更多”样式*/
    .service-info-title-more {
        background-color: #235ae8;
        float: right;
        font-weight: normal !important;
        font-size: 16px !important;
        display: inline-block;
        padding: 5px 20px;
        color: white;
        cursor: pointer;
        border-radius: 55px 55px 55px 55px;
    }
</style>
